import { EmailRequestEntry } from '@endaoment-frontend/types';

// Signatures & Auth
export const SIGNATURE_TERMS_AND_CONDITIONS =
  'I agree to the Terms & Conditions described at https://docs.endaoment.org/governance ** ACCOUNT: ';
export const TOKEN_STORAGE_KEY = 'ndao-token';

// Connectors
export const CONNECTOR_LOCALSTORAGE_KEY = 'connector_id';
export const WALLET_CONNECT_LOCALSTORAGE_KEY = 'walletconnect';

// Tokens and Values
export const ENDAOMENT_DONATION_FEE = 0.005;
export const ENDAOMENT_GRANT_FEE = 0.01;
export const MIN_DONATION_FALLBACK = 500;
export const MIN_ORG_BALANCE_THRESHOLD = 99;
export const MIN_GRANT = 500;
export const STABLECOIN_DECIMALS = 6;
export const SLIPPAGE_WARNING_THRESHOLD = 50_000;
export const PRICE_IMPACT_WARNING_THRESHOLD = 5;

// Transactions
export const QUEUE_LOCALSTORAGE_KEY = 'transaction-queue';
export const COMPLETED_QUEUE_HOLD_TIME = 60_000;

// Endaoment Docs
export const TERMS_AND_CONDITIONS_URL = 'https://docs.endaoment.org/governance/terms-and-conditions';
export const PRIVACY_POLICY_URL = 'https://docs.endaoment.org/governance/personal-data-usage-and-storage';
export const NO_DONOR_BENEFIT_URL = 'https://docs.endaoment.org/governance/fund-agreement#no-donor-benefit-clause';
export const ORGANIZATION_FUNDING_POLICY_URL =
  'https://docs.endaoment.org/governance/mission-values#organization-funding-policy';

// Misc.
export const GTM_ID = 'GTM-KNGLST8';
export const METAMASK_CHROME_INSTALL_URL =
  'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
export const METAMASK_FIREFOX_INSTALL_URL = 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask';
export const METAMASK_EDGE_INSTALL_URL =
  'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US';

export const PAYPAL_DONATE_URL = 'https://www.paypal.com/donate?hosted_button_id=';

export const OTC_URL = 'https://endaoment.org/otc';

export const PLACID_FUND_GENERATOR_URL = 'https://api.placid.app/u/mau6sylhu';
export const PLACID_ORG_GENERATOR_URL = 'https://api.placid.app/u/stvsezlck';

export const ORG_COMPLIANCE_ERRORS = {
  ofacOK_check: 'OFAC Issue',
  pub78_verified_check: 'Missing Pub 78 Entry',
  irs_bmf_pub78_conflict_check: 'Pub 78 Conflict',
  bmf_status_check: 'Missing Business Main File',
  section_509a_check: 'Non-Operating Private Foundation',
};

export const US_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

// ADMIN TAB EMAILS
export type AdminTab = 'funds' | 'grants' | 'donations' | 'claims' | 'payouts';

export const ADMIN_TABS_EMAILS: Record<AdminTab, EmailRequestEntry> = {
  grants: {
    emailRequest: 'newGrantAlerts',
    emailTemplates: [
      'jcf_grant_receipt',
      'grant_receipt',
      'grant_receipt_SI',
      'grant_receipt_C',
      'grant_receipt_UC',
      'grant_receipt_SI_C',
      'grant_receipt_SI_UC',
    ],
  },
  funds: { emailRequest: 'newFundAlerts', emailTemplates: ['jcf_fund_receipt'] },
  donations: { emailRequest: 'newDonationAlerts', emailTemplates: ['jcf_gift_receipt', 'direct_donation'] },
  payouts: {
    emailRequest: 'adminWithdrawalEmails',
    emailTemplates: ['jcf_withdrawal_receipt', 'wire_withdrawal_receipt'],
  },
  claims: {
    emailRequest: 'adminClaimEmails',
    emailTemplates: ['org_deployed_alert', 'jcf_claim_approval', 'jcf_claim_receipt'],
  },
};

export const ADMIN_IGNORE_EMAIL_FIELDS: string[] = ['overrides'];

export const COPY_SHARE_TEXT = 'Copy this page and share it';

export const DEFAULT_STALETIME_SECONDS = 30;
export const SSG_STALETIME_SECONDS_HOME = 60;
export const SSG_STALETIME_SECONDS_ORGS = 3600;
export const SSG_STALETIME_SECONDS_ORG = 60;
export const SSG_STALETIME_SECONDS_VANITY_FUNDS = 60;
