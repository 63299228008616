import { IconProps } from './Icon.types';

export const CloseIcon = ({ width = 11, height, color = 'currentColor', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 10L26 26M10 26L26 10" stroke={color} strokeWidth="4" strokeLinecap="round" />
  </svg>
);
