import { AxiosResponse } from 'axios';

// Type narrowing for AxiosResponse
export const isAxiosResponse = (obj: unknown): obj is AxiosResponse => {
  if (obj === undefined || obj === null) return false;

  if (typeof obj === 'object') {
    return (
      'data' in obj && 'status' in obj && 'statusText' in obj && 'headers' in obj && 'config' in obj && 'request' in obj
    );
  }

  return false;
};
