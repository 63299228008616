import clsx from 'clsx';
import { useState } from 'react';

import { config } from '@endaoment-frontend/config';

import { Button } from '../button';

import styles from './CommitBox.module.scss';

const BRANCH = process.env['COMMIT_BRANCH'];
const HASH = process.env['COMMIT_HASH'];
const ENABLED = config.environmentName === 'staging' && !!BRANCH && !!HASH;

// To be used purely for development purposes
export const CommitBox = () => {
  const [isOpen, setIsOpen] = useState(true);

  if (!ENABLED) return null;

  return (
    <div className={clsx(styles['commit-box'], !isOpen && styles['commit-box--hidden'])} role="complementary">
      <a href={`https://github.com/endaoment/endaoment-frontend/commit/${HASH}`} target="_blank" rel="noreferrer">
        {HASH.substring(0, 8)}
      </a>
      <strong>{BRANCH}</strong>

      <Button variation="default" onClick={() => setIsOpen(false)}>
        Hide
      </Button>
    </div>
  );
};
