import { useId } from 'react';

import { IconProps } from './Icon.types';

export const LogoIcon = ({ width = 35, height, color, strokeColor, strokeWidth, ...props }: IconProps) => {
  const gradientId = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0173 14.6937L29.3825 16.6671L28.402 20.3263L17.3702 17.3703L20.3261 28.402L16.6669 29.3825L14.7505 22.2301L15.5932 18.866C16.0149 17.1825 17.3294 15.868 19.0129 15.4463L22.0173 14.6937ZM22.4564 14.572L29.383 12.716L28.4025 9.05683L17.3701 12.013L20.326 0.981323L16.6669 0.000854201L14.7792 7.04581L15.5853 10.3574C16.0095 12.0999 17.3876 13.4496 19.1386 13.8373L22.4564 14.572ZM14.6369 7.16967L12.7158 0L9.05664 0.980468L12.0128 12.0129L0.980468 9.05684L0 12.716L7.37291 14.6916L0 16.6671L0.980469 20.3263L0.980924 20.3262L0.980957 20.3263L12.0127 17.3703L9.0569 28.4017L9.05657 28.4029L12.7157 29.3834L12.7161 29.3821L14.6664 22.1032L13.8555 18.866C13.4338 17.1825 12.1193 15.868 10.4358 15.4463L6.96748 14.5775L10.4358 13.7086C12.1193 13.2869 13.4338 11.9724 13.8555 10.2889L14.6369 7.16967ZM6.85769 8.36445C8.20496 8.00345 9.00448 6.61863 8.64349 5.27137C8.28249 3.9241 6.89767 3.12458 5.5504 3.48558C4.20314 3.84657 3.40361 5.23139 3.76461 6.57866C4.12561 7.92592 5.51043 8.72545 6.85769 8.36445ZM8.64349 22.452C9.00448 23.7993 8.20496 25.1841 6.85769 25.5451C5.51043 25.9061 4.12561 25.1066 3.76461 23.7593C3.40361 22.4121 4.20314 21.0272 5.5504 20.6662C6.89767 20.3052 8.28249 21.1048 8.64349 22.452ZM23.8532 8.36445C25.2004 8.00345 26 6.61863 25.639 5.27137C25.278 3.9241 23.8932 3.12458 22.5459 3.48558C21.1986 3.84657 20.3991 5.23139 20.7601 6.57866C21.1211 7.92592 22.5059 8.72545 23.8532 8.36445ZM25.639 22.452C26 23.7993 25.2004 25.1841 23.8532 25.5451C22.5059 25.9061 21.1211 25.1066 20.7601 23.7593C20.3991 22.4121 21.1986 21.0272 22.5459 20.6662C23.8931 20.3052 25.278 21.1048 25.639 22.452Z"
        fill={color ?? `url(#${gradientId})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <defs>
        <linearGradient id={gradientId} x1="14.6915" y1="0" x2="14.6915" y2="29.3834" gradientUnits="userSpaceOnUse">
          <stop stopColor="#52ACDE" />
          <stop offset="0.3125" stopColor="#A99FFF" />
          <stop offset="0.671875" stopColor="#F76464" />
          <stop offset="1" stopColor="#FF9548" />
        </linearGradient>
      </defs>
    </svg>
  );
};
