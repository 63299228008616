import Link from 'next/link';

import { config } from '@endaoment-frontend/config';
import { ArrowIcon, LogoTextIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles['footer-wrapper']}>
      <div className={styles['links-container']}>
        <div className={styles['links-logo']}>
          <LogoTextIcon color="currentColor" />
          <span>Crypto&apos;s Community Foundation</span>
        </div>
        <div>
          <h6>Information</h6>
          {/* <Link href="/ndao">
            <a>NDAO Token</a>
          </Link> */}
          <Link href="/about">
            <a>About Endaoment</a>
          </Link>
          <Link href="/otc">
            <a>Over-the-Counter Donations</a>
          </Link>
          <Link href="/learn">
            <a>Learn</a>
          </Link>
          <a href="https://docs.endaoment.org" target="_blank" rel="noreferrer">
            Documentation
          </a>
        </div>
        <div>
          <h6>Endaoment App</h6>
          <a href={config.clientBaseUrl} target="_blank" rel="noreferrer">
            App Home
          </a>
          <a href={`${config.clientBaseUrl}/orgs`} target="_blank" rel="noreferrer">
            Search Organizations
          </a>
        </div>
        <div>
          <h6>Resources for Nonprofits</h6>
          <a href="https://docs.endaoment.org/organizations" target="_blank" rel="noreferrer">
            Nonprofit Documentation
          </a>
          <Link href="/orgs">
            <a>Info for Nonprofits</a>
          </Link>
        </div>
        <div>
          <h6>Resources for Donors</h6>
          <a href="https://docs.endaoment.org/donors" target="_blank" rel="noreferrer">
            Donor Documentation
          </a>
          <a
            href="https://endaoment.typeform.com/to/IWxBhuMP?typeform-source=endaoment.org"
            target="_blank"
            rel="noreferrer"
          >
            OTC Donation Form
          </a>
          <Link href="/advisors">
            <a>Info for Financial Advisors</a>
          </Link>
        </div>
      </div>
      <hr />
      <div className={styles['extra-container']}>
        <div>
          <p className={styles['ndao-ein']}>Endaoment is a registered United States 501(c)(3) with EIN 84-4661797</p>
          <span className={styles['ndao-copyright']}>© 2022 Endaoment</span>
        </div>
        <Button as="a" href={config.clientBaseUrl} className={styles['extra-app-button']}>
          Launch App
          <ArrowIcon width={20} height={12} />
        </Button>
      </div>
    </footer>
  );
};
