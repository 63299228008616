import { useEffect, useState } from 'react';

export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

export function useScroll(minMove = 0) {
  const [lastY, setLastY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.Down);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const scrollHandler = () => {
      const currY = window.scrollY;
      const yDiff = currY - lastY;

      if (Math.abs(yDiff) > minMove) {
        setScrollDirection(yDiff > 0 ? ScrollDirection.Down : ScrollDirection.Up);
        setLastY(currY);
      }
    };
    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  return {
    scrollDirection,
    y: lastY,
  };
}
