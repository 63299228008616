import clsx from 'clsx';
import { m, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';

import { config } from '@endaoment-frontend/config';
import { useScroll, ScrollDirection } from '@endaoment-frontend/hooks';
import {
  ArrowIcon,
  CloseIcon,
  DownCaretIcon,
  HamburgerIcon,
  LogoIcon,
  LogoTextIcon,
} from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import { useNavbarDisplayOverride } from '../../lib/useNavbarDisplayOverride';

import styles from './NavBar.module.scss';

// There's no good way to import SCSS variable for this into JS
const NAVBAR_HEIGHT = 96;

const ActiveLink = ({
  href,
  children,
  onClick,
  className,
}: {
  href: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  const { asPath } = useRouter();

  return (
    <Link href={href}>
      <a className={clsx(asPath === href && styles['active'], className)} onClick={onClick}>
        {children}
      </a>
    </Link>
  );
};

export const NavBar = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const [displayOverride] = useNavbarDisplayOverride();
  const { y, scrollDirection } = useScroll();
  const isInView: boolean =
    displayOverride !== undefined ? displayOverride : scrollDirection === ScrollDirection.Up || y <= NAVBAR_HEIGHT;

  const closeHamburger = () => {
    setHamburgerOpen(false);
  };

  return (
    <AnimatePresence initial={false}>
      {isInView && (
        <m.header
          key="header"
          className={styles['header']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <div className={styles['header__inner']}>
            <div className={clsx(styles['hamburger-icon'], styles['show-mobile'])}>
              <HamburgerIcon
                onClick={() => {
                  setHamburgerOpen(true);
                }}
              />
            </div>
            <nav className={clsx('flex-row', styles['navigation'], styles['show-desktop'])}>
              <ActiveLink href="/">Home</ActiveLink>
              <ActiveLink href="/about">About</ActiveLink>
              <ActiveLink href="/ndao">Token</ActiveLink>
              <div className={styles['learn-container']}>
                <ActiveLink href="/learn">
                  Learn <DownCaretIcon />
                </ActiveLink>
                <div>
                  <ActiveLink href="/donors" className={styles['show-desktop']}>
                    For Donors
                  </ActiveLink>
                  <ActiveLink href="/orgs" className={styles['show-desktop']}>
                    For Nonprofits
                  </ActiveLink>
                  <ActiveLink href="/advisors" className={styles['show-desktop']}>
                    For Financial Advisors
                  </ActiveLink>
                </div>
              </div>
              <ActiveLink href="/otc">OTC</ActiveLink>
              <a href="https://docs.endaoment.org" target="_blank" rel="noreferrer">
                Docs
              </a>
            </nav>
            <div className={styles['logo']}>
              <Link href="/">
                <a>
                  <LogoTextIcon width={128} height={30} className={styles['show-desktop']} />
                  <LogoIcon width={30} className={styles['show-mobile']} />
                </a>
              </Link>
            </div>
            <div className={styles['extra']}>
              <Button as="a" href={config.clientBaseUrl} className={styles['app-button']}>
                Launch App
                <ArrowIcon width={20} height={12} />
              </Button>
            </div>
          </div>
        </m.header>
      )}
      {hamburgerOpen && (
        <m.div
          key="hamburger"
          className={styles['hamburger-menu']}
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 1 }}
        >
          <CloseIcon width={20} color="#696F8C" onClick={closeHamburger} />
          <nav className={clsx('flex-column', styles['navigation'])}>
            <ActiveLink href="/about" onClick={closeHamburger}>
              About
            </ActiveLink>
            <ActiveLink href="/ndao" onClick={closeHamburger}>
              Token
            </ActiveLink>
            <ActiveLink href="/learn" onClick={closeHamburger}>
              Learn
            </ActiveLink>
            <ActiveLink href="/donors" onClick={closeHamburger} className={styles['sub-link']}>
              For Donors
            </ActiveLink>
            <ActiveLink href="/orgs" onClick={closeHamburger} className={styles['sub-link']}>
              For Nonprofits
            </ActiveLink>
            <ActiveLink href="/advisors" onClick={closeHamburger} className={styles['sub-link']}>
              For Financial Advisors
            </ActiveLink>
            <ActiveLink href="/otc" onClick={closeHamburger}>
              OTC
            </ActiveLink>
            <a href="https://docs.endaoment.org" target="_blank" rel="noreferrer">
              Docs
            </a>
            <Button as="a" href={config.clientBaseUrl}>
              Launch App
              <ArrowIcon width={20} height={12} />
            </Button>
          </nav>
          <div>
            <hr />
            <LogoIcon width={60} />
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};
