import { dehydrate, QueryClient, QueryClientConfig } from 'react-query';

import { DEFAULT_STALETIME_SECONDS } from '@endaoment-frontend/constants';
import { isAxiosResponse } from '@endaoment-frontend/types';

// Creates new instance of QueryClient - useful to repeat to avoid caching between SSG/server requests
export const newQueryClient = (additionalConfig: QueryClientConfig = {}) =>
  new QueryClient({
    ...additionalConfig,
    defaultOptions: {
      ...additionalConfig.defaultOptions,
      queries: { staleTime: DEFAULT_STALETIME_SECONDS * 1000, retry: 3, ...additionalConfig.defaultOptions?.queries },
    },
  });

// Exports main 'canonical' app-wide query client
export const queryClient = newQueryClient();

// Execute a series of queries, used in getStaticProps to make a dehydrated QueryClient
export const makeDehydratedQueries = async (...queries: Parameters<QueryClient['prefetchQuery']>[]) => {
  const queryClient = newQueryClient();
  for (const queryArgs of queries) {
    await queryClient.prefetchQuery(...queryArgs);
    const data = queryClient.getQueryData(queryArgs[0]);
    if (isAxiosResponse(data)) {
      queryClient.setQueryData(queryArgs[0], () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { config, request, ...response } = data;

        return response;
      });
    }
  }

  return dehydrate(queryClient);
};
