import { LazyMotion } from 'framer-motion';
import { AppProps } from 'next/app';
import Head from 'next/head';
import '../styles/globals.scss';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { newQueryClient } from '@endaoment-frontend/api';
import { GTM_ID } from '@endaoment-frontend/constants';
import { CommitBox, CookieModal } from '@endaoment-frontend/ui/shared';

import { Footer } from '../content/footer';
import { NavBar } from '../content/navBar';

const loadMotionFeatures = () => import('framer-motion').then((m) => m.domAnimation);

const App = ({ Component, pageProps, router }: AppProps) => {
  const [queryClient] = useState(() => newQueryClient());

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  return (
    <LazyMotion features={loadMotionFeatures} strict>
      <Head>
        <title>Endaoment</title>
        <link rel="icon" href="/images/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <NavBar />
      <main className="app">
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <Component {...pageProps} />
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </main>
      <Footer />
      <CookieModal />
      <CommitBox />
    </LazyMotion>
  );
};

export default App;
