import { AnimatePresence, m } from 'framer-motion';
import { useLocalStorage } from 'react-use';

import { Button } from '../button';
import { Card } from '../card';

import styles from './CookieModal.module.scss';

const MotionCard = m(Card);

export const CookieModal = () => {
  const [cookieModalOpen, setCookieModalOpen] = useLocalStorage('cookieModalOpen', true);

  const closeModal = () => {
    setCookieModalOpen(false);
  };

  return (
    <AnimatePresence initial>
      {cookieModalOpen && (
        <MotionCard
          className={styles['cookie-modal']}
          initial={{ x: '100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '100vw', opacity: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <div>
            <h3>We Use Cookies</h3>
            <p>Cookies make interacting with our Ethereum contracts a more cohesive process.</p>
          </div>
          <Button onClick={closeModal} filled size="medium" variation="fund">
            Okay
          </Button>
        </MotionCard>
      )}
    </AnimatePresence>
  );
};
