import clsx from 'clsx';
import { ElementType, forwardRef, useCallback, useState } from 'react';

import { PolymorphicRef } from '@endaoment-frontend/types';
import { mergeRefs } from '@endaoment-frontend/utils';

import styles from './Button.module.scss';
import { ButtonProps } from './Button.types';

export const Button = forwardRef(
  <Tag extends ElementType>(
    {
      variation = 'default',
      size = 'medium',
      fontSize,
      minimal = false,
      filled = false,
      shadowed = false,
      gradient = false,
      card = false,
      cardIcon,
      className,
      children,
      as,
      ...restProps
    }: ButtonProps<Tag>,
    ref: PolymorphicRef<Tag>,
  ) => {
    const Component = as ?? 'button';
    // Hack to check if svg is child and apply proper styles
    const [hasIcon, setHasIcon] = useState(false);
    const hasIconRef = useCallback((node: HTMLButtonElement) => {
      if (node) {
        for (const childNode of Array.from(node.childNodes)) {
          if (childNode.nodeName === 'svg') {
            setHasIcon(true);
            return;
          }
        }
      }
      setHasIcon(false);
    }, []);
    return (
      <Component
        ref={mergeRefs([ref, hasIconRef])}
        {...restProps}
        className={clsx(
          styles['button'],
          styles[`variation--${variation}`],
          styles[`size--${size}`],
          styles[`font-size--${fontSize ?? size}`],
          minimal && styles['minimal'],
          gradient && styles['gradient'],
          filled && styles['filled'],
          shadowed && styles['shadowed'],
          hasIcon && styles['icon'],
          cardIcon && styles['card--icon'],
          (card || cardIcon) && styles['card'],
          className,
        )}
      >
        {cardIcon && <div className={styles['icon-div']}>{cardIcon}</div>}
        {children}
      </Component>
    );
  },
);
Button.displayName = 'Button';
