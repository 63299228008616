import { useId } from 'react';

import { IconProps } from './Icon.types';

export const LogoTextIcon = ({ width = 152, height = 42, color, ...props }: IconProps) => {
  const gradientId = useId();
  return (
    <svg width={width} height={height} viewBox="0 0 128 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1746 15.002L29.5397 16.9755L28.5593 20.6346L17.5274 17.6786L20.4833 28.7103L16.8242 29.6908L14.9077 22.5384L15.7504 19.1743C16.1721 17.4908 17.4866 16.1763 19.1701 15.7546L22.1746 15.002ZM22.6137 14.8803L29.5402 13.0243L28.5598 9.36515L17.5273 12.3213L20.4833 1.28964L16.8241 0.309173L14.9364 7.35413L15.7425 10.6657C16.1667 12.4082 17.5449 13.7579 19.2959 14.1456L22.6137 14.8803ZM14.7941 7.47798L12.873 0.308319L9.21387 1.28879L12.17 12.3213L1.13769 9.36516L0.157227 13.0243L7.53014 14.9999L0.157227 16.9755L1.1377 20.6346L1.13815 20.6345L1.13818 20.6346L12.17 17.6787L9.21413 28.71L9.21379 28.7112L12.873 29.6917L12.8733 29.6905L14.8237 22.4115L14.0127 19.1743C13.591 17.4908 12.2765 16.1763 10.593 15.7546L7.12471 14.8858L10.593 14.0169C12.2765 13.5952 13.591 12.2807 14.0127 10.5972L14.7941 7.47798ZM7.01492 8.67277C8.36218 8.31177 9.16171 6.92695 8.80071 5.57969C8.43971 4.23242 7.05489 3.4329 5.70763 3.79389C4.36036 4.15489 3.56084 5.53971 3.92183 6.88698C4.28283 8.23424 5.66765 9.03377 7.01492 8.67277ZM8.80071 22.7604C9.16171 24.1076 8.36218 25.4924 7.01492 25.8534C5.66765 26.2144 4.28283 25.4149 3.92183 24.0676C3.56084 22.7204 4.36036 21.3356 5.70763 20.9746C7.05489 20.6136 8.43971 21.4131 8.80071 22.7604ZM24.0104 8.67277C25.3577 8.31177 26.1572 6.92695 25.7962 5.57969C25.4352 4.23242 24.0504 3.4329 22.7031 3.79389C21.3558 4.15489 20.5563 5.53971 20.9173 6.88698C21.2783 8.23424 22.6631 9.03377 24.0104 8.67277ZM25.7962 22.7603C26.1572 24.1076 25.3577 25.4924 24.0104 25.8534C22.6631 26.2144 21.2783 25.4149 20.9173 24.0676C20.5563 22.7204 21.3558 21.3356 22.7031 20.9746C24.0504 20.6136 25.4352 21.4131 25.7962 22.7603Z"
        fill={color ?? `url(#${gradientId})`}
      />
      <path
        d="M55.0648 10.8701C53.8487 10.8701 52.9453 11.3739 52.3893 12.1731C52.3893 11.6166 51.9382 11.1655 51.3816 11.1655H50.2871V20.0433H52.5457V14.6923C52.5457 13.5804 53.2927 12.8159 54.3351 12.8159C55.4123 12.8159 55.9856 13.4761 55.9856 14.6401V20.0433H58.2442V13.9626C58.2442 12.1557 57.1323 10.8701 55.0648 10.8701Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M66.0495 7.70813V12.0862C65.4588 11.3392 64.5901 10.9222 63.3913 10.9222C61.1328 10.9222 59.4475 12.7291 59.4475 15.6131C59.4475 18.4797 61.1154 20.3039 63.374 20.3039C64.6943 20.3039 65.6151 19.7653 66.2058 18.8793C66.2058 19.5222 66.727 20.0433 67.3699 20.0433H68.308V7.70813H66.0495ZM63.8952 18.3581C62.5574 18.3581 61.7408 17.2809 61.7408 15.6131C61.7408 13.9278 62.6269 12.8854 63.9125 12.8854C65.1634 12.8854 66.1363 13.8757 66.1363 15.6131C66.1363 17.3157 65.1982 18.3581 63.8952 18.3581Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M73.8264 10.8701C72.0717 10.8701 70.7861 11.5477 69.6741 12.8681L71.0988 14.1711C72.0196 13.285 72.645 12.7291 73.8612 12.7291C74.8862 12.7291 75.529 13.2155 75.529 14.0842V14.2927L73.4442 14.6749C71.4289 15.0397 69.6047 15.7521 69.6047 17.8021C69.6047 19.609 71.0988 20.3387 72.784 20.3387C74.1565 20.3387 75.1121 19.8696 75.6506 19.1225C75.6506 19.631 76.0629 20.0433 76.5714 20.0433H77.6833V14.0495C77.6833 12.1731 76.3803 10.8701 73.8264 10.8701ZM73.1662 18.5318C72.3149 18.5318 71.8111 18.2017 71.8111 17.6458C71.8111 16.8118 72.6971 16.4991 74.0349 16.2211L75.529 15.9084V16.4296C75.529 17.9759 74.3129 18.5318 73.1662 18.5318Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M83.4786 10.8701C80.7683 10.8701 78.8573 12.7812 78.8573 15.5957C78.8573 18.4102 80.7683 20.3213 83.4786 20.3213C86.1715 20.3213 88.0826 18.4102 88.0826 15.5957C88.0826 12.7812 86.1715 10.8701 83.4786 10.8701ZM83.4786 12.7812C84.8685 12.7812 85.7893 13.8931 85.7893 15.5957C85.7893 17.3157 84.8685 18.4102 83.4786 18.4102C82.0714 18.4102 81.1506 17.3157 81.1506 15.5957C81.1506 13.8931 82.0714 12.7812 83.4786 12.7812Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M99.0239 10.8701C97.7209 10.8701 96.887 11.4261 96.3658 12.1731C95.8967 11.3566 95.0628 10.8701 93.8988 10.8701C92.6479 10.8701 91.8313 11.3913 91.3622 12.1557C91.3622 11.6088 90.9189 11.1655 90.3719 11.1655H89.26V20.0433H91.5186V14.588C91.5186 13.5977 92.0572 12.8159 93.0822 12.8159C94.1246 12.8159 94.4895 13.5282 94.4895 14.5185V20.0433H96.748V14.588C96.748 13.5804 97.2866 12.8159 98.3116 12.8159C99.3367 12.8159 99.7189 13.5282 99.7189 14.5185V20.0433H101.977V13.841C101.977 12.0341 100.935 10.8701 99.0239 10.8701Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M107.862 18.4102C106.316 18.4102 105.551 17.472 105.36 16.1864H111.736C111.754 15.9953 111.771 15.5783 111.771 15.3003C111.771 12.5727 110.329 10.8701 107.619 10.8701C104.822 10.8701 103.154 12.9028 103.154 15.6478C103.154 18.4797 104.943 20.3213 107.81 20.3213C109.252 20.3213 110.468 19.9043 111.771 18.5839L110.503 17.1941C109.46 18.0627 108.904 18.4102 107.862 18.4102ZM107.549 12.6422C108.783 12.6422 109.478 13.3893 109.564 14.5707H105.412C105.655 13.424 106.35 12.6422 107.549 12.6422Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M117.774 10.8701C116.558 10.8701 115.655 11.3739 115.099 12.1731C115.099 11.6166 114.648 11.1655 114.091 11.1655H112.997V20.0433H115.255V14.6923C115.255 13.5804 116.002 12.8159 117.045 12.8159C118.122 12.8159 118.695 13.4761 118.695 14.6401V20.0433H120.954V13.9626C120.954 12.1557 119.842 10.8701 117.774 10.8701Z"
        fill={color ?? '#394C62'}
      />
      <path
        d="M127.581 18.1322C127.008 18.2712 126.73 18.2886 126.469 18.2886C125.879 18.2886 125.583 18.0975 125.583 17.472V12.8854H127.703V11.1655H125.583V8.42044H123.325V11.1655H121.744V12.8854H123.325V17.75C123.325 19.4005 124.037 20.2518 125.844 20.2518C126.417 20.2518 127.06 20.1823 127.842 19.9217L127.581 18.1322Z"
        fill={color ?? '#394C62'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9634 7.70813H39.9871V8.86539H39.9864L39.9864 12.6295H39.9863V14.946H39.9864L39.9864 17.8426H39.9863V20.1591H48.9626V17.8426H42.3029L42.3029 14.946H48.9626V12.6295H42.3029L42.3029 10.0246H48.9634V7.70813Z"
        fill={color ?? '#394C62'}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="14.8487"
          y1="0.308319"
          x2="14.8487"
          y2="29.6917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#52ACDE" />
          <stop offset="0.3125" stopColor="#A99FFF" />
          <stop offset="0.671875" stopColor="#F76464" />
          <stop offset="1" stopColor="#FF9548" />
        </linearGradient>
      </defs>
    </svg>
  );
};
