import { atom, useAtom } from 'jotai';

type DisplayOverrideAtomType = boolean | undefined;

const displayOverrideEnabled = atom(false);
const displayOverrideValue = atom(false);
const displayOverrideAtom = atom<DisplayOverrideAtomType, DisplayOverrideAtomType>(
  (get) => (get(displayOverrideEnabled) ? get(displayOverrideValue) : undefined),
  (_get, set, newVal) => {
    if (typeof newVal === 'undefined') {
      set(displayOverrideEnabled, false);
      return;
    }

    set(displayOverrideEnabled, true);
    set(displayOverrideValue, newVal);
  },
);

export const useNavbarDisplayOverride = () => useAtom(displayOverrideAtom);
