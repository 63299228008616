import clsx from 'clsx';
import { ElementType, forwardRef } from 'react';

import { PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Card.module.scss';
import { CardProps } from './Card.types';

export const Card = forwardRef(
  <Tag extends ElementType = 'div'>(
    { children, className, onSelect, rainbow, role, as, ...props }: CardProps<Tag>,
    ref: PolymorphicRef<Tag>,
  ) => {
    const Component = as ?? 'div';
    return (
      <Component
        ref={ref}
        className={clsx(
          styles['card'],
          className,
          onSelect && styles['card--clickable'],
          rainbow && styles['card--rainbow'],
        )}
        onClick={onSelect}
        role={onSelect ? 'button' : role ?? 'banner'}
        tabIndex={onSelect && 0}
        onKeyDown={(e) => e.key === 'Enter' && onSelect?.()}
        {...props}
      >
        {rainbow ? <div className={styles['content']}>{children}</div> : children}
      </Component>
    );
  },
);
Card.displayName = 'Card';
