import { IconProps } from './Icon.types';

export const ArrowIcon = ({
  color = 'currentColor',
  width = 40,
  height = 25,
  strokeWidth = 2,
  className,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 7.5H23M23 7.5L16.5 1M23 7.5L16.5 14"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
