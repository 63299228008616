import { ConfigEntries, Environment } from '@endaoment-frontend/types';

// @TODO: Update env with correct url for new environments and names ('development' | 'staging' | 'testnet' | 'production')
const configEntries: ConfigEntries = {
  development: {
    environmentName: 'development',
    chainId: 0x3,
    chainName: 'ropsten',
    explorerUrlPrefix: {
      transaction: 'https://ropsten.etherscan.io/tx',
      address: 'https://ropsten.etherscan.io/address',
    },
    infura: {
      projectId: 'cdf3bdd9227744688fb91b658146d51b',
      url: 'https://ropsten.infura.io/v3',
    },
    clientBaseUrl: 'http://localhost:3350',
    // apiBaseUrl: process.env['NEXT_PUBLIC_API_URL'] || 'http://localhost:8080',
    apiBaseUrl: 'http://localhost:8080',
    endaoment: {
      accounts: {
        accountant: '0x9928Fa2b51f7e2ccE76Cf0a6aED1b7e4afE0CC0f',
        pauser: '0xBb77D2c8c6feC55285C782356333afDcd4A926D8',
        admin: '0x3EEaC5d43d47228fF1cC5116537d80E51285B886',
        reviewer: '0x78062b69CffD97e6E0bBA4Cd71B0EC436B634E10',
      },
      contracts: {
        admin: '0x70BA8e66030D870B3d984f72BCB80E2367Fd385d',
        fundFactory: '0x4D0E6bb5fd44f1A6dB6A08193b81478a44FB42c1',
        orgFactory: '0xF491F990Fd29a5AfE025Ece483d27287b346A083',
      },
    },
    stableCoinAddress: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  },

  staging: {
    environmentName: 'staging',
    chainId: 0x3,
    chainName: 'ropsten',
    explorerUrlPrefix: {
      transaction: 'https://ropsten.etherscan.io/tx',
      address: 'https://ropsten.etherscan.io/address',
    },
    infura: {
      projectId: 'cdf3bdd9227744688fb91b658146d51b',
      url: 'https://ropsten.infura.io/v3',
    },
    // clientBaseUrl: 'https://app-staging.endaoment.org',
    clientBaseUrl: 'https://test.endaoment.org',
    apiBaseUrl: 'https://api-test.endaoment.org',
    endaoment: {
      accounts: {
        accountant: '0x9928Fa2b51f7e2ccE76Cf0a6aED1b7e4afE0CC0f',
        pauser: '0xBb77D2c8c6feC55285C782356333afDcd4A926D8',
        admin: '0x3EEaC5d43d47228fF1cC5116537d80E51285B886',
        reviewer: '0x78062b69CffD97e6E0bBA4Cd71B0EC436B634E10',
      },
      contracts: {
        admin: '0x70BA8e66030D870B3d984f72BCB80E2367Fd385d',
        fundFactory: '0x4D0E6bb5fd44f1A6dB6A08193b81478a44FB42c1',
        orgFactory: '0xF491F990Fd29a5AfE025Ece483d27287b346A083',
      },
    },
    stableCoinAddress: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  },

  testnet: {
    environmentName: 'testnet',
    chainId: 0x3,
    chainName: 'ropsten',
    explorerUrlPrefix: {
      transaction: 'https://ropsten.etherscan.io/tx',
      address: 'https://ropsten.etherscan.io/address',
    },
    infura: {
      projectId: 'cdf3bdd9227744688fb91b658146d51b',
      url: 'https://ropsten.infura.io/v3',
    },
    // clientBaseUrl: 'https://testnet.endaoment.org',
    clientBaseUrl: 'https://ropsten.endaoment.org',
    apiBaseUrl: 'https://api-ropsten.endaoment.org',
    endaoment: {
      accounts: {
        accountant: '0x9928Fa2b51f7e2ccE76Cf0a6aED1b7e4afE0CC0f',
        pauser: '0xBb77D2c8c6feC55285C782356333afDcd4A926D8',
        admin: '0x3EEaC5d43d47228fF1cC5116537d80E51285B886',
        reviewer: '0x78062b69CffD97e6E0bBA4Cd71B0EC436B634E10',
      },
      contracts: {
        admin: '0x70BA8e66030D870B3d984f72BCB80E2367Fd385d',
        fundFactory: '0x4D0E6bb5fd44f1A6dB6A08193b81478a44FB42c1',
        orgFactory: '0xF491F990Fd29a5AfE025Ece483d27287b346A083',
      },
    },
    stableCoinAddress: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  },

  production: {
    environmentName: 'production',
    chainId: 0x1,
    chainName: 'mainnet',
    explorerUrlPrefix: {
      transaction: 'https://etherscan.io/tx',
      address: 'https://etherscan.io/address',
    },
    infura: {
      projectId: 'cdf3bdd9227744688fb91b658146d51b',
      url: 'https://mainnet.infura.io/v3',
    },
    clientBaseUrl: 'https://app.endaoment.org',
    // apiBaseUrl: 'https://api.endaoment.org',
    apiBaseUrl: 'https://api-app.endaoment.org',
    endaoment: {
      accounts: {
        accountant: '0x9D5025B327E6B863E5050141C987d988c07fd8B2',
        pauser: '0x9d9d5A6cdbdCd1D7D4261861A30Db1A806154662',
        admin: '0x20ECa8049FD40260442F2B784F2a4e7Bb06c51cD',
        reviewer: '0xBE21e4cf884c8B2517E4E199487f8b505841cB36',
      },
      contracts: {
        admin: '0x2a108b1810890482fDB3bE188616c56dD0170A8E',
        fundFactory: '0x59Bf396e21D8E941415ae80Ab7347bdF82d22D6e',
        orgFactory: '0xf2f5BB5d80413Dd1fEea6254786465109ADF4b9E',
      },
    },
    stableCoinAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  },
};

const env = process.env['NEXT_PUBLIC_ENV'] as Environment | undefined;

if (!env) throw 'Environment variable `NEXT_PUBLIC_ENV` not set!';

const config = configEntries[env];

// OPTIONAL OVERRIDES
config.apiBaseUrl = process.env['NEXT_PUBLIC_API_BASE_URL'] || config.apiBaseUrl;

export { config };
